import apiClient from '../apiClient';

/**
 * Получить все документы.
 */
export const getAdminDocumentsApi = async () => {
    try {
        const response = await apiClient.get(`/documents`);
        return response.data;
    } catch (error) {
        console.error('[Admin Documents API] Error fetching documents:', error);
        throw error;
    }
};

/**
 * Получить документ по ID.
 * @param {number} id - ID документа.
 */
export const getAdminDocumentByIdApi = async (id) => {
    try {
        const response = await apiClient.get(`/documents/get-documents?id=${id}`);
        return response.data;
    } catch (error) {
        console.error('[Admin Documents API] Error fetching document by ID:', error);
        throw error;
    }
};

/**
 * Создать новый документ.
 * @param {string} title - Заголовок документа.
 * @param {string} content - Содержимое документа.
 */
export const createAdminDocumentApi = async (title, content) => {
    try {
        const response = await apiClient.post(`/documents`, {
            title: title,
            content: content,
        });
        return response.data;
    } catch (error) {
        console.error('[Admin Documents API] Error creating document:', error);
        throw error;
    }
};

/**
 * Удалить документ по ID.
 * @param {number} id - ID документа.
 */
export const deleteAdminDocumentApi = async (id) => {
    try {
        const response = await apiClient.delete(`/documents`, {
            headers: {
                'Content-Type': 'application/json',
            },
            data: { id: id }, // Передаем ID в теле запроса
        });
        return response.data;
    } catch (error) {
        console.error('[Admin Documents API] Error deleting document:', error);
        throw error;
    }
};

/**
 * Обновить документ.
 * @param {number} id - ID документа.
 * @param {string} title - Новый заголовок документа.
 * @param {string} content - Новое содержимое документа.
 */
export const updateAdminDocumentApi = async (id, title, content) => {
    try {
        const response = await apiClient.post(`/documents/update`, {
            id: parseFloat(id),
            title: title,
            content: content,
        });
        return response.data;
    } catch (error) {
        console.error('[Admin Documents API] Error updating document:', error);
        throw error;
    }
};
