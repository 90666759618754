// src/utils/api/feedbacksApi.js

import apiClient from "../apiClient";

/**
 * Получение всех отзывов.
 * @returns {Promise<Object>} Список всех отзывов.
 */
export const getAllFeedbacksApi = async () => {
    try {
        const response = await apiClient.get("/feedback/all");
        return response.data;
    } catch (error) {
        console.error("[Feedbacks API] Error fetching all feedbacks:", error);
        throw error;
    }
};

/**
 * Получение отзывов по ID пользователя.
 * @param {number} userId - ID пользователя.
 * @returns {Promise<Object>} Отзывы пользователя.
 */
export const getFeedbacksByUserIdApi = async (userId) => {
    try {
        const response = await apiClient.get(`/feedback?userId=${userId}`);
        return response.data;
    } catch (error) {
        console.error("[Feedbacks API] Error fetching feedbacks by userId:", error);
        throw error;
    }
};

/**
 * Удаление отзыва по ID.
 * @param {number} id - ID отзыва.
 * @returns {Promise<Object>} Ответ сервера.
 */
export const deleteFeedbackApi = async (id) => {
    try {
        const response = await apiClient.delete(`/feedback?id=${id}`);
        return response.data;
    } catch (error) {
        console.error("[Feedbacks API] Error deleting feedback:", error);
        throw error;
    }
};
