//
// import React, { useEffect, useState } from "react";
// import Sidebar from "../../components/Sidebar";
// import Header from "../../components/Header";
// import Footer from "../../components/Footer";
// import DataTable from "react-data-table-component";
// import { useAdminUsers } from "../../hooks/useAdminUsers"; // Подключаем хук
//
// const NewsPage = ({ handleLogout }) => {
//     const [filterText, setFilterText] = useState(""); // Состояние для поиска
//     const [fromDate, setFromDate] = useState(""); // Начальная дата
//     const [toDate, setToDate] = useState(""); // Конечная дата
//
//     const {
//         usersData,
//         loading,
//         error,
//         fetchAdminUsers,
//         blockAdminUser,
//         blockLoading,
//         blockError,
//         successMessage,
//         blockAdminLoading,
//         blockAdminError,
//         fetchBlockAdmin,
//     } = useAdminUsers();
//
//     useEffect(() => {
//         fetchAdminUsers({ page: 1, limit: 1000000 });
//     }, [fetchAdminUsers]);
//
//     const handleGetData = () => {
//         const params = {
//             page: 1,
//             limit: 1000000,
//             ...(fromDate && { startDate: fromDate }),
//             ...(toDate && { endDate: toDate }),
//         };
//         fetchAdminUsers(params);
//     };
//
//     const resetFilters = () => {
//         setFilterText("");
//         setFromDate("");
//         setToDate("");
//         fetchAdminUsers({ page: 1, limit: 1000000 });
//     };
//
//     const handleBlockUser = async (userId, isBlocked, isAdmin) => {
//         if (isAdmin) {
//             await fetchBlockAdmin(userId, isBlocked);
//         } else {
//             await blockAdminUser(userId, isBlocked);
//         }
//         handleGetData();
//     };
//
//     const filteredUsers =
//         usersData?.users.filter((user) => {
//             const searchTerm = filterText.toLowerCase();
//             return (
//                 user.telegramUsername?.toLowerCase().includes(searchTerm) ||
//                 user.id.toString().includes(searchTerm) ||
//                 user.firstName?.toLowerCase().includes(searchTerm) ||
//                 user.lastName?.toLowerCase().includes(searchTerm)
//             );
//         }) || [];
//
//     const columns = [
//         { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
//         { name: "First Name", selector: (row) => row.firstName || "-", sortable: true, width: "150px" },
//         { name: "Last Name", selector: (row) => row.lastName || "-", sortable: true, width: "150px" },
//         { name: "Telegram Username", selector: (row) => row.telegramUsername || "-", sortable: true, width: "200px" },
//         { name: "Role", selector: (row) => row.userRole || "-", sortable: true, width: "120px" },
//         { name: "Public ID", selector: (row) => row.publicId, sortable: true, width: "150px" },
//         {
//             name: "Date Registered",
//             selector: (row) => formatDate(row.createdAt),
//             sortable: true,
//             width: "200px",
//         },
//         {
//             name: "Status",
//             cell: (row) => {
//                 const permission = row.userRole === "ADMIN" ? row.adminPermission : row.userPermission;
//                 return (
//                     <span style={{ color: permission === "BLOCK" ? "red" : "green" }}>
//                         {permission === "BLOCK" ? "Blocked" : "Active"}
//                     </span>
//                 );
//             },
//             width: "120px",
//         },
//         {
//             name: "Action",
//             cell: (row) => {
//                 const isAdmin = row.userRole === "ADMIN";
//                 const permission = isAdmin ? row.adminPermission : row.userPermission;
//                 return (
//                     <button
//                         style={{ width: "100px" }}
//                         className="bann-btn"
//                         onClick={() => handleBlockUser(row.id, permission !== "BLOCK", isAdmin)}
//                         disabled={isAdmin ? blockAdminLoading : blockLoading}
//                     >
//                         {permission === "BLOCK" ? "Unblock" : "Block"}
//                     </button>
//                 );
//             },
//             width: "150px",
//         },
//     ];
//
//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         return date.toLocaleString();
//     };
//
//     return (
//         <div className="wrapper">
//             <Sidebar page={"users"} />
//             <div className="page">
//                 <Header handleLogout={handleLogout} />
//                 <main className="main users">
//                     <h1 className="users__title title" style={{ marginBottom: 20 }}>Users</h1>
//
//                     {/*{successMessage && <p style={{ color: "green" }}>{successMessage}</p>}*/}
//                     {/*{blockError && <p style={{ color: "red" }}>Error: {blockError.message}</p>}*/}
//                     {/*{blockAdminError && <p style={{ color: "red" }}>Error: {blockAdminError.message}</p>}*/}
//
//                     <div className="datatable-top-block">
//                         <input
//                             type="text"
//                             placeholder="Search by ID or Username"
//                             value={filterText}
//                             onChange={(e) => setFilterText(e.target.value)}
//                             className="datatable-search-input"
//                         />
//                     </div>
//
//                     <div className="users__table table-users table" style={{ overflowX: "auto" }}>
//                         {loading ? (
//                             <p>Loading...</p>
//                         ) : error ? (
//                             <p>Error: {error.message}</p>
//                         ) : (
//                             <DataTable
//                                 columns={columns}
//                                 data={filteredUsers}
//                                 pagination
//                                 highlightOnHover
//                                 fixedHeader
//                             />
//                         )}
//                     </div>
//                 </main>
//                 <Footer />
//             </div>
//         </div>
//     );
// };
//
// export default NewsPage;

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DataTable from "react-data-table-component";
import { useNews } from "../../hooks/useNews";
import {Link} from "react-router-dom"; // Подключаем хук для новостей

const NewsPage = ({ handleLogout }) => {
    const [filterText, setFilterText] = useState(""); // Состояние для поиска
    const [selectedNewsId, setSelectedNewsId] = useState(null); // Состояние для выбранной новости
    const [showDeletePopup, setShowDeletePopup] = useState(false); // Состояние для показа попапа удаления

    const {
        allNewsAnswer,
        allNewsLoading,
        allNewsError,
        fetchGetAllNews,
        deleteNewsLoading,
        deleteNewsError,
        fetchDeleteNews,
    } = useNews();

    useEffect(() => {
        fetchGetAllNews();
    }, [fetchGetAllNews]);

    const handleDeleteNews = async (id) => {
        await fetchDeleteNews(parseFloat(id));
        setShowDeletePopup(false);
    };

    const filteredNews =
        allNewsAnswer && allNewsAnswer?.filter((news) => {
            const searchTerm = filterText.toLowerCase();
            return news.title.toLowerCase().includes(searchTerm) || news.id.toString().includes(searchTerm);
        }) || [];

    const columns = [
        { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
        { name: "Title", selector: (row) => row.title, sortable: true, width: "300px" },
        {
            name: "Date Created",
            selector: (row) => formatDate(row.createdAt),
            sortable: true,
            width: "200px",
        },
        {
            name: "Actions",
            cell: (row) => (
                <div style={{ display: "flex", gap: "10px" }}>
                    <Link
                        to={`/news/${row.id}`}
                        style={{ width: "100px" }}
                        className="edit-btn"
                        onClick={() => handleEditNews(row.id)}
                    >
                        Edit
                    </Link>
                    <button
                        style={{ width: "100px" }}
                        className="delete-btn"
                        onClick={() => {
                            setSelectedNewsId(row.id);
                            setShowDeletePopup(true);
                        }}
                    >
                        Delete
                    </button>
                </div>
            ),
            width: "220px",
        },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    const handleEditNews = (id) => {
        console.log(`Editing news with ID: ${id}`);
        // Здесь может быть редирект на страницу редактирования или открытие модального окна
    };

    return (
        <div className="wrapper">
            <Sidebar page={"news"} />
            <div className="page">
                <Header handleLogout={handleLogout} />
                <main className="main news">
                    <div style={{display: 'flex', justifyContent:'space-between'}}>
                        <h1 className="news__title title" style={{marginBottom: 20}}>Новости</h1>

                        <Link to={'/news/new'}>
                            Создать новость
                        </Link>
                    </div>

                    <div className="datatable-top-block">
                        <input
                            type="text"
                            placeholder="Search by Title or ID"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className="datatable-search-input"
                        />
                    </div>

                    <div className="news__table table-news table" style={{ overflowX: "auto" }}>
                        {allNewsLoading ? (
                            <p>Loading...</p>
                        ) : allNewsError ? (
                            <p>Error: {allNewsError.message}</p>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={filteredNews}
                                pagination
                                highlightOnHover
                                fixedHeader
                            />
                        )}
                    </div>
                </main>
                <Footer />
            </div>

            {/* Попап для подтверждения удаления */}
            {showDeletePopup && (
                <div className="popup">
                    <div className="popup-content">
                        <h3>Are you sure you want to delete this news?</h3>
                        <div className="popup-actions">
                            <button
                                className="confirm-delete-btn"
                                onClick={() => handleDeleteNews(selectedNewsId)}
                                disabled={deleteNewsLoading}
                            >
                                {deleteNewsLoading ? "Deleting..." : "Yes, Delete"}
                            </button>
                            <button className="cancel-btn" onClick={() => setShowDeletePopup(false)}>
                                Cancel
                            </button>
                        </div>
                        {deleteNewsError && <p style={{ color: "red" }}>Error: {deleteNewsError.message}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewsPage;
