// import React, {useEffect, useState} from 'react';
// import Sidebar from "../../components/Sidebar";
// import Header from "../../components/Header";
// import Footer from "../../components/Footer";
// import {useFees} from "../../hooks/useFees";
//
// // Компонент формы для изменения комиссии или начисления
// const FeeChangeForm = ({ title, currentFee, defaultNewFee, defaultStartDate, onChange }) => {
//     const [newFee, setNewFee] = useState(defaultNewFee);
//     const [startDate, setStartDate] = useState(defaultStartDate);
//     const [isDeferred, setIsDeferred] = useState(true);
//
//
//     useEffect(() => {
//         setNewFee(defaultNewFee)
//     }, [defaultNewFee]);
//
//     // const handleSubmit = (e) => {
//     //     e.preventDefault();
//     //     // Здесь можно реализовать логику для сохранения изменений
//     //     console.log("Новое значение:", newFee, "Дата начала:", startDate, "Отложенный запуск:", isDeferred);
//     // };
//
//     return (
//         <div className="fees__item">
//             <h2 className="fees__item-title">{title}</h2>
//             <div className="fees__form form" >
//                 <div className="form__items">
//                     <div className="form__item">
//                         <input
//                             className="form-control"
//                             type="text"
//                             value={newFee}
//                             onChange={(e) => setNewFee(e.target.value)}
//                         />
//                     </div>
//
//                 </div>
//
//                 <button onClick={()=>{onChange(newFee)}} type="submit" className="button">Изменить</button>
//             </div>
//         </div>
//     );
// };
//
// const CommissionsAndCharges = () => {
//
//
//     const {feesDataSEND, feesDataRECEIVE, feesDataEXCHANGE, fetchGetFees, loading, error,
//         changeFeesLoading, changeFeesError, changeFeesAnswer, fetchChangeFees
//     } = useFees();
//
//     useEffect(() => {
//         fetchGetFees();
//     }, []);
//
//     useEffect(() => {
//         console.log(feesDataSEND, feesDataRECEIVE, feesDataEXCHANGE, 'feesDataSEND, feesDataRECEIVE, feesDataEXCHANGE')
//
//         console.log(feesDataEXCHANGE?.fee?.amount ? feesDataEXCHANGE?.fee?.amount : 0)
//     }, [feesDataSEND, feesDataRECEIVE, feesDataEXCHANGE]);
//
//
//     return (
//         <div className="wrapper">
//             <Sidebar page={'/commissions-and-charges'} />
//
//             <div className="page">
//                 <Header />
//
//                 {/* Main Section */}
//                 <main className="main fees">
//                     <h1 className="fees__title title">Комиссии и начисления</h1>
//                     <div className="fees__items">
//                         <FeeChangeForm
//                             title="Изменить комиссию получения"
//                             currentFee="3%"
//                             defaultNewFee={feesDataRECEIVE?.fee ? feesDataRECEIVE?.fee?.amount : 0}
//                             defaultStartDate="16.08.2024 15:00"
//                             onChange={(amount)=>{
//                                 fetchChangeFees('RECEIVE', parseFloat(amount))
//                             }}
//                         />
//                         <FeeChangeForm
//                             title="Изменить комиссию отправления"
//                             currentFee="0,2%"
//                             defaultNewFee={feesDataSEND?.fee ? feesDataSEND?.fee?.amount : 0}
//                             defaultStartDate="16.08.2024 15:00"
//                             onChange={(amount)=>{
//                                 fetchChangeFees('SEND', parseFloat(amount))
//                             }}
//                         />
//                         <FeeChangeForm
//                             title="Изменить комиссию обмена"
//                             currentFee="0,2%"
//                             defaultNewFee={feesDataEXCHANGE?.fee?.amount ? feesDataEXCHANGE?.fee?.amount : 0}
//                             defaultStartDate="16.08.2024 15:00"
//                             onChange={(amount)=>{
//                                 fetchChangeFees('EXCHANGE', parseFloat(amount))
//                             }}
//                         />
//                     </div>
//                 </main>
//
//                 <Footer />
//             </div>
//         </div>
//     );
// };
//
// export default CommissionsAndCharges;


import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useFees } from "../../hooks/useFees";

// Компонент формы для изменения комиссии или начисления
const FeeChangeForm = ({ title, currentFee, defaultNewFee, defaultStartDate, onChange }) => {
    const [newFee, setNewFee] = useState(defaultNewFee);

    useEffect(() => {
        setNewFee(defaultNewFee);
    }, [defaultNewFee]);

    return (
        <div className="fees__item">
            <h2 className="fees__item-title">{title}</h2>
            <div className="fees__form form">
                <div className="form__items">
                    <div className="form__item">
                        <input
                            className="form-control"
                            type="text"
                            value={newFee}
                            onChange={(e) => {

                                const value = e.target.value;
                                // Проверяем, что вводятся только цифры (с поддержкой десятичной точки)
                                if (/^\d*\.?\d*$/.test(value)) {
                                    setNewFee(value);
                                }
                                // setNewFee(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <button onClick={() => onChange(newFee)} type="submit" className="button">
                    Изменить
                </button>
            </div>
        </div>
    );
};

// Компонент модального окна
const FeesSuccessModal = ({ message, onClose }) => (
    <div className="fees-modal-overlay">
        <div className="fees-modal">
            <h2>Успех</h2>
            <p>{message}</p>
            <button className="fees-modal-button" onClick={onClose}>
                OK
            </button>
        </div>
    </div>
);

const CommissionsAndCharges = ({handleLogout}) => {
    const {
        feesDataSEND,
        feesDataRECEIVE,
        feesDataEXCHANGE,
        fetchGetFees,
        loading,
        error,
        changeFeesLoading,
        changeFeesError,
        changeFeesAnswer,
        fetchChangeFees,


        updateFeesLoading,
        updateFeesError,
        updateFeesAnswer,
        fetchUpdateFees

    } = useFees();

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    useEffect(() => {
        fetchGetFees();
    }, [fetchGetFees]);

    useEffect(() => {
        if (changeFeesAnswer) {
            setShowSuccessModal(true);
        }
    }, [changeFeesAnswer]);

    const handleCloseModal = () => {
        setShowSuccessModal(false);
    };

    return (
        <div className="wrapper">
            <Sidebar page={'commissions-and-charges'} />

            <div className="page">
                <Header handleLogout={handleLogout}/>

                {/* Main Section */}
                <main className="main fees">
                    <h1 className="fees__title title">Комиссии и начисления</h1>
                    <div className="fees__items">
                        <FeeChangeForm
                            title="Изменить комиссию получения"
                            currentFee="3%"
                            defaultNewFee={feesDataRECEIVE?.fee ? feesDataRECEIVE?.fee?.amount : 0}
                            defaultStartDate="16.08.2024 15:00"
                            onChange={(amount) => {
                                if (feesDataRECEIVE?.fee?.createdAt) {
                                    fetchUpdateFees('RECEIVE', parseFloat(amount));
                                } else {
                                    fetchChangeFees('RECEIVE', parseFloat(amount));
                                }
                            }}
                        />
                        <FeeChangeForm
                            title="Изменить комиссию отправления"
                            currentFee="0,2%"
                            defaultNewFee={feesDataSEND?.fee ? feesDataSEND?.fee?.amount : 0}
                            defaultStartDate="16.08.2024 15:00"
                            onChange={(amount) => {

                                if (feesDataSEND?.fee?.createdAt) {
                                    fetchUpdateFees('SEND', parseFloat(amount));
                                } else {
                                    fetchChangeFees('SEND', parseFloat(amount));
                                }
                            }}
                        />
                        <FeeChangeForm
                            title="Изменить комиссию обмена"
                            currentFee="0,2%"
                            defaultNewFee={feesDataEXCHANGE?.fee?.amount ? feesDataEXCHANGE?.fee?.amount : 0}
                            defaultStartDate="16.08.2024 15:00"
                            onChange={(amount) => {
                                if(feesDataEXCHANGE?.fee?.createdAt) {
                                    fetchUpdateFees('EXCHANGE', parseFloat(amount));
                                } else {
                                    fetchChangeFees('EXCHANGE', parseFloat(amount));
                                }
                            }}
                        />
                    </div>
                </main>

                <Footer />
            </div>

            {/* Модальное окно для успешного обновления */}
            {showSuccessModal && (
                <FeesSuccessModal
                    message="Комиссия успешно обновлена!"
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
};

export default CommissionsAndCharges;
