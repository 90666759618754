// src/utils/api/transactionsApi.js

import apiClient from "../apiClient";

/**
 * Получение всех транзакций.
 * @returns {Promise<Object>} Список транзакций.
 */
export const getAllTransactionsApi = async () => {
    try {
        const response = await apiClient.get("/transactions/all");
        return response.data;
    } catch (error) {
        console.error("[Transactions API] Error fetching transactions:", error);
        throw error;
    }
};
