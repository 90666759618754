import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {useDocuments} from "../../hooks/useDocument";

const DocumentCreatePage = ({ handleLogout }) => {
    const navigate = useNavigate();

    const {
        fetchCreateDocument,
        createDocumentLoading,
        createDocumentError,
    } = useDocuments();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetchCreateDocument(title, content);
        navigate("/documents");
    };

    return (
        <div className="wrapper">
            <Sidebar page="documents" />
            <div className="page">
                <Header handleLogout={handleLogout} />
                <main className="main document-create">
                    <h1 className="title">Create Document</h1>
                    <form onSubmit={handleSubmit} className="document-form">
                        <div className="form-group">
                            <label>Title</label>
                            <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Content</label>
                            <textarea
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                required
                            />
                        </div>
                        <button className={'button'} type="submit" disabled={createDocumentLoading}>
                            {createDocumentLoading ? "Creating..." : "Create Document"}
                        </button>
                        {createDocumentError && <p style={{ color: "red" }}>{createDocumentError.message}</p>}
                    </form>
                </main>
                <Footer />
            </div>
        </div>
    );
};

export default DocumentCreatePage;
