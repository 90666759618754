import * as React from "react";

const SvgIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="22"
        fill="none"
        viewBox="0 0 26 22"
    >
        <path
            fill="#000"
            d="M7.375 11a.937.937 0 0 1 .938-.937h8.437V3.968c0-1.875-1.98-3.281-3.75-3.281H4.094a3.285 3.285 0 0 0-3.282 3.28v14.063a3.285 3.285 0 0 0 3.282 3.282h9.375a3.284 3.284 0 0 0 3.281-3.282v-6.093H8.313A.94.94 0 0 1 7.375 11m17.538-.663L20.225 5.65A.938.938 0 0 0 18.9 6.975l3.087 3.088H16.75v1.874h5.236L18.9 15.026a.939.939 0 1 0 1.325 1.325l4.688-4.687a.94.94 0 0 0 0-1.326"
        ></path>
    </svg>
);

export default SvgIcon;
