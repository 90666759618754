import React, {useEffect, useRef, useState} from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Main from './pages/Main';

import Loader from "./components/Loader";
import { SingleProvider } from './context/SingleContext';
import CourseManagement from "./pages/CourseManagement";
import TransactionHistory from "./pages/TransactionHistory";
import ChatPage from "./pages/ChatsPage";
import CommissionsAndCharges from "./pages/CommissionsAndCharges";
import CashPickUpAddresses from "./pages/CashPickUpAddresses";
import LoginPage from "./pages/LoginPage";
import {useAuth} from "./context/AuthContext";
import Users from "./pages/Users";
import NewsPage from "./pages/News";
import NewsEditPage from "./pages/NewsEdit";
import NewsCreate from "./pages/NewsCreate";
import DocumentsListPage from "./pages/Documents";
import DocumentEditPage from "./pages/DocumentsEdit";
import DocumentCreatePage from "./pages/DocumentsCreate";
import TransactionsListPage from "./pages/TransactionsListPage";
import FeedbacksListPage from "./pages/FeedbacksListPage"; // Импортируем контекст

function App() {


    const { user, logout } = useAuth();

    const [loader, setLoader] = useState(true); // Показатель загрузки
    const [isLogin, setIsLogin] = useState(false); // Состояние авторизации
    const navigate = useNavigate();
    const location = useLocation();

    // Проверка токена при загрузке приложения
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLogin(true);
            // Перенаправляем на главную страницу
            if (location.pathname === '/login') {
                navigate('/');
            }
        } else {
            setIsLogin(false);
            navigate('/login');
        }
    }, [location.pathname, navigate, isLogin]);

    // Функция для выхода из системы
    const handleLogout = () => {
        localStorage.removeItem('token');
        setIsLogin(false);
        navigate('/login');
    };

    return (
        <Routes>

            {user ? (
                <>
                    <Route path="/" element={<Users  handleLogout={handleLogout}/>} />
                    <Route path="/commissions-and-charges" element={<CommissionsAndCharges  handleLogout={handleLogout}/>} />
                    <Route path="/news" element={<NewsPage  handleLogout={handleLogout}/>} />
                    <Route path="/news/:id" element={<NewsEditPage  handleLogout={handleLogout}/>} />
                    <Route path="/news/new" element={<NewsCreate  handleLogout={handleLogout}/>} />

                    <Route path="/documents" element={<DocumentsListPage  handleLogout={handleLogout}/>} />
                    <Route path="/documents/edit/:id" element={<DocumentEditPage handleLogout={handleLogout} />} />
                    <Route path="/documents/new" element={<DocumentCreatePage handleLogout={handleLogout} />} />

                    <Route path="/transactions" element={<TransactionsListPage handleLogout={handleLogout} />} />
                    <Route path="/feedback" element={<FeedbacksListPage handleLogout={handleLogout} />} />

                    {/*<Route path="/course-management" element={<CourseManagement  handleLogout={handleLogout}/>} />*/}
                    {/*<Route path="/transaction-history" element={<TransactionHistory  handleLogout={handleLogout}/>} />*/}
                    {/*<Route path="/chat-page" element={<ChatPage  handleLogout={handleLogout}/>} />*/}
                    {/*<Route path="/commissions-and-charges" element={<CommissionsAndCharges  handleLogout={handleLogout}/>} />*/}
                    {/*<Route path="/cash-pickup-addresses" element={<CashPickUpAddresses  handleLogout={handleLogout}/>} />*/}
                </>
            ) : (
                <Route path="/login" element={<LoginPage setIsLogin={setIsLogin} />} />
            )}

        </Routes>
    );
}

export default App;
