// src/hooks/useTransactions.js

import { useState, useCallback } from "react";
import { getAllTransactionsApi } from "../utils/api/transactionsApi";

/**
 * Хук для получения и управления транзакциями.
 */
export const useTransactions = () => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    /**
     * Получение всех транзакций.
     */
    const fetchTransactions = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getAllTransactionsApi();
            setTransactions(data?.items || []);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }, []);

    return {
        transactions,
        loading,
        error,
        fetchTransactions,
    };
};
