import axios from 'axios';

// Загружаем переменные окружения
const apiUrl = process.env.REACT_APP_API_URL;

// Создание экземпляра axios с базовой конфигурацией
const apiClient = axios.create({
    baseURL: apiUrl, // базовый URL для API
    headers: {
        'Content-Type': 'application/json',
    }
});

// Интерцептор для добавления токена из localStorage в заголовки каждого запроса
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // Получаем токен из localStorage
        if (token) {
            console.log(token, 'token')
            config.headers['Authorization'] = `${token}`; // Добавляем токен в заголовок
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Функция для логаута пользователя
const logoutUser = () => {
    localStorage.removeItem('token'); // Удаляем токен
    window.location.href = '/login'; // Перенаправляем пользователя на страницу логина
};

// Интерцептор для обработки ошибок
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // Если запрос вернул ошибку 401 (Unauthorized) и это не запрос на логин
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            // Удаляем токен и выходим из системы
            // logoutUser();
        }

        return Promise.reject(error);
    }
);

export default apiClient;
