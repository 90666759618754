// // src/pages/FeedbacksListPage.jsx
//
// import React, { useEffect, useState } from "react";
// import Sidebar from "../../components/Sidebar";
// import Header from "../../components/Header";
// import Footer from "../../components/Footer";
// import DataTable from "react-data-table-component";
// import { useFeedbacks } from "../../hooks/useFeedbacks";
// import {useAdminUsers} from "../../hooks/useAdminUsers";
//
// const FeedbacksListPage = ({ handleLogout }) => {
//     const {
//         allFeedbacks,
//         allFeedbacksLoading,
//         allFeedbacksError,
//         fetchAllFeedbacks,
//         fetchDeleteFeedback,
//         deleteFeedbackLoading,
//         deleteFeedbackError,
//     } = useFeedbacks();
//
//     const { userData, userLoading, userError, fetchAdminUserById} = useAdminUsers();
//
//     const [selectedFeedback, setSelectedFeedback] = useState(null);
//     const [showDeleteModal, setShowDeleteModal] = useState(false);
//     const [showDetailsModal, setShowDetailsModal] = useState(false);
//
//     useEffect(() => {
//         fetchAllFeedbacks();
//     }, [fetchAllFeedbacks]);
//
//     const handleDeleteClick = (id) => {
//         setSelectedFeedback(id);
//         setShowDeleteModal(true);
//     };
//
//
//     useEffect(() => {
//         if (userData) {
//             // setSelectedFeedback({
//             //     selectedFeedback: selectedFeedback,
//             //     userData:userData
//             // });
//             setShowDetailsModal(true);
//             console.log(userData, 'userData')
//         }
//     }, [userData])
//     const handleConfirmDelete = () => {
//         fetchDeleteFeedback(selectedFeedback);
//         setShowDeleteModal(false);
//     };
//
//     const handleDetailsClick = (feedback) => {
//
//         fetchAdminUserById(feedback?.userId);
//         console.log(feedback, 'feedback')
//         setSelectedFeedback(feedback);
//         // setShowDetailsModal(true);
//     };
//
//     const columns = [
//         { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
//         { name: "Title", selector: (row) => row.title, sortable: true, width: "150px" },
//         { name: "Content", selector: (row) => row.content, sortable: true, width: "150px" },
//         {
//             name: "Created At",
//             selector: (row) => new Date(row.createdAt).toLocaleString(),
//             sortable: true,
//             width: "200px",
//         },
//         {
//             name: "Actions",
//             cell: (row) => (
//                 <div style={{ display: "flex", gap: "10px" }}>
//                     <button onClick={() => handleDetailsClick(row)} className="feedbacks-details-btn">
//                         Details
//                     </button>
//                     <button
//                         onClick={() => handleDeleteClick(row.id)}
//                         className="feedbacks-delete-btn"
//                         disabled={deleteFeedbackLoading}
//                     >
//                         {deleteFeedbackLoading ? "Deleting..." : "Delete"}
//                     </button>
//                 </div>
//             ),
//             width: "200px",
//         },
//     ];
//
//     return (
//         <div className="wrapper">
//             <Sidebar page="feedbacks" />
//             <div className="page">
//                 <Header handleLogout={handleLogout} />
//                 <main className="main feedbacks">
//                     <h1 className="feedbacks__title title" style={{ marginBottom: 20 }}>
//                         Feedbacks
//                     </h1>
//                     {allFeedbacksLoading ? (
//                         <p>Loading...</p>
//                     ) : allFeedbacksError ? (
//                         <p style={{ color: "red" }}>Error: {allFeedbacksError.message}</p>
//                     ) : (
//                         <DataTable columns={columns} data={allFeedbacks} pagination highlightOnHover />
//                     )}
//                 </main>
//                 <Footer />
//             </div>
//
//             {/* Модальное окно для удаления */}
//             {showDeleteModal && (
//                 <div className="feedbacks-modal-overlay">
//                     <div className="feedbacks-modal">
//                         <h3>Are you sure you want to delete this feedback?</h3>
//                         <div className="feedbacks-modal-actions">
//                             <button className="feedbacks-confirm-btn" onClick={handleConfirmDelete}>
//                                 Yes, Delete
//                             </button>
//                             <button className="feedbacks-cancel-btn" onClick={() => setShowDeleteModal(false)}>
//                                 Cancel
//                             </button>
//                         </div>
//                         {deleteFeedbackError && <p style={{ color: "red" }}>Error: {deleteFeedbackError.message}</p>}
//                     </div>
//                 </div>
//             )}
//
//             {/* Модальное окно для отображения деталей */}
//             {showDetailsModal && selectedFeedback && (
//                 <div className="feedbacks-modal-overlay">
//                     <div style={{width:'90%'}} className="feedbacks-modal">
//                         <h3 style={{marginBottom:10}}>Feedback Details</h3>
//                         <p><strong>First name:</strong> {userData.firstName}</p>
//                         <p><strong>Last name:</strong> {userData.lastName}</p>
//                         <p><strong>Publick ID:</strong> {userData.publicId}</p>
//                         <p><strong>Tg user ID:</strong> {userData.telegramUserId}</p>
//                         <p><strong>Tg user name:</strong> {userData.telegramUsername}</p>
//                         <p><strong>Title:</strong> {selectedFeedback.title}</p>
//                         <p><strong>Content:</strong> {selectedFeedback.content}</p>
//                         <p style={{marginBottom:10}}><strong>Email:</strong> {userData.email}</p>
//                         {/*<p><strong>Status:</strong> {selectedFeedback.status}</p>*/}
//                         {/*<p><strong>Deposit State:</strong> {selectedFeedback.depositState}</p>*/}
//                         {/*<p><strong>Currency:</strong> {selectedFeedback.currency?.cryptoCode}</p>*/}
//                         {/*<p><strong>Value:</strong> {selectedFeedback.value}</p>*/}
//                         {/*<p><strong>Created At:</strong> {new Date(selectedFeedback.createdAt).toLocaleString()}</p>*/}
//                         <button className="feedbacks-close-btn" onClick={() => setShowDetailsModal(false)}>
//                             Close
//                         </button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };
//
// export default FeedbacksListPage;


// src/pages/FeedbacksListPage.jsx

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DataTable from "react-data-table-component";
import { useFeedbacks } from "../../hooks/useFeedbacks";
import { useAdminUsers } from "../../hooks/useAdminUsers";

const FeedbacksListPage = ({ handleLogout }) => {
    const {
        allFeedbacks,
        allFeedbacksLoading,
        allFeedbacksError,
        fetchAllFeedbacks,
        fetchDeleteFeedback,
        deleteFeedbackLoading,
        deleteFeedbackError,
    } = useFeedbacks();

    const { userData, userLoading, userError, fetchAdminUserById } = useAdminUsers();

    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [filterText, setFilterText] = useState(""); // Состояние для поиска
    const [filteredFeedbacks, setFilteredFeedbacks] = useState([]); // Состояние для отфильтрованных отзывов

    useEffect(() => {
        fetchAllFeedbacks();
    }, [fetchAllFeedbacks]);

    useEffect(() => {
        // Фильтрация отзывов по тексту поиска
        if (filterText.trim()) {
            setFilteredFeedbacks(
                allFeedbacks.filter(
                    (feedback) =>
                        feedback.title.toLowerCase().includes(filterText.toLowerCase()) ||
                        feedback.content.toLowerCase().includes(filterText.toLowerCase()) ||
                        feedback.id == filterText
                )
            );
        } else {
            setFilteredFeedbacks(allFeedbacks);
        }
    }, [filterText, allFeedbacks]);

    const handleDeleteClick = (id) => {
        setSelectedFeedback(id);
        setShowDeleteModal(true);
    };

    useEffect(() => {
        if (userData) {
            setShowDetailsModal(true);
        }
    }, [userData]);

    const handleConfirmDelete = () => {
        fetchDeleteFeedback(selectedFeedback);
        setShowDeleteModal(false);
    };

    const handleDetailsClick = (feedback) => {
        fetchAdminUserById(feedback?.userId);
        setSelectedFeedback(feedback);
    };

    const columns = [
        { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
        { name: "Title", selector: (row) => row.title, sortable: true, width: "150px" },
        { name: "Content", selector: (row) => row.content, sortable: true, width: "150px" },
        {
            name: "Created At",
            selector: (row) => new Date(row.createdAt).toLocaleString(),
            sortable: true,
            width: "200px",
        },
        {
            name: "Actions",
            cell: (row) => (
                <div style={{ display: "flex", gap: "10px" }}>
                    <button onClick={() => handleDetailsClick(row)} className="feedbacks-details-btn">
                        Details
                    </button>
                    <button
                        onClick={() => handleDeleteClick(row.id)}
                        className="feedbacks-delete-btn"
                        disabled={deleteFeedbackLoading}
                    >
                        {deleteFeedbackLoading ? "Deleting..." : "Delete"}
                    </button>
                </div>
            ),
            width: "200px",
        },
    ];

    return (
        <div className="wrapper">
            <Sidebar page="feedbacks" />
            <div className="page">
                <Header handleLogout={handleLogout} />
                <main className="main feedbacks">
                    <h1 className="feedbacks__title title" style={{ marginBottom: 20 }}>
                        Feedbacks
                    </h1>

                    {/* Поле поиска */}
                    <div style={{ marginBottom: 20 }}>
                        <input
                            type="text"
                            placeholder="Search by Title or Content Or ID"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            style={{
                                width: "100%",
                                padding: "10px",
                                fontSize: "14px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                            }}
                        />
                    </div>

                    {allFeedbacksLoading ? (
                        <p>Loading...</p>
                    ) : allFeedbacksError ? (
                        <p style={{ color: "red" }}>Error: {allFeedbacksError.message}</p>
                    ) : (
                        <DataTable columns={columns} data={filteredFeedbacks} pagination highlightOnHover />
                    )}
                </main>
                <Footer />
            </div>

            {/* Модальное окно для удаления */}
            {showDeleteModal && (
                <div className="feedbacks-modal-overlay">
                    <div className="feedbacks-modal">
                        <h3>Are you sure you want to delete this feedback?</h3>
                        <div className="feedbacks-modal-actions">
                            <button className="feedbacks-confirm-btn" onClick={handleConfirmDelete}>
                                Yes, Delete
                            </button>
                            <button className="feedbacks-cancel-btn" onClick={() => setShowDeleteModal(false)}>
                                Cancel
                            </button>
                        </div>
                        {deleteFeedbackError && <p style={{ color: "red" }}>Error: {deleteFeedbackError.message}</p>}
                    </div>
                </div>
            )}

            {/* Модальное окно для отображения деталей */}
            {showDetailsModal && selectedFeedback && (
                <div className="feedbacks-modal-overlay">
                    <div style={{ width: "90%" }} className="feedbacks-modal">
                        <h3 style={{ marginBottom: 10 }}>Feedback Details</h3>
                        <p>
                            <strong>First name:</strong> {userData?.firstName}
                        </p>
                        <p>
                            <strong>Last name:</strong> {userData?.lastName}
                        </p>
                        <p>
                            <strong>Public ID:</strong> {userData?.publicId}
                        </p>
                        <p>
                            <strong>Telegram User ID:</strong> {userData?.telegramUserId}
                        </p>
                        <p>
                            <strong>Telegram Username:</strong> {userData?.telegramUsername}
                        </p>
                        <p>
                            <strong>Title:</strong> {selectedFeedback?.title}
                        </p>
                        <p>
                            <strong>Content:</strong> {selectedFeedback?.content}
                        </p>
                        <p style={{ marginBottom: 10 }}>
                            <strong>Email:</strong> {userData?.email}
                        </p>
                        <button className="feedbacks-close-btn" onClick={() => setShowDetailsModal(false)}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FeedbacksListPage;
