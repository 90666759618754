// import React, { createContext, useContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
//
// const AuthContext = createContext();
//
// export const AuthProvider = ({ children }) => {
//     const [user, setUser] = useState(null);
//     const navigate = useNavigate();
//
//     const login = (data) => {
//         const { token, role } = data;
//         localStorage.setItem("token", token); // Сохраняем только токен
//         setUser({ role }); // Храним роль только в памяти
//         navigate("/");
//     };
//
//     const logout = () => {
//         localStorage.removeItem("token");
//         setUser(null);
//         navigate("/login");
//     };
//
//     useEffect(() => {
//         // Проверяем токен при загрузке
//         const token = localStorage.getItem("token");
//         if (token) {
//             // Запрашиваем роль пользователя с сервера
//             fetch("https://olimpusplay.com/api/admin/profile", {
//                 method: "GET",
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             })
//                 .then((res) => res.json())
//                 .then((data) => {
//                     if (data.role) {
//                         setUser({ role: data.role });
//                     } else {
//                         logout();
//                     }
//                 })
//                 .catch(logout);
//         }
//     }, []);
//
//     return (
//         <AuthContext.Provider value={{ user, login, logout }}>
//             {children}
//         </AuthContext.Provider>
//     );
// };
//
// export const useAuth = () => useContext(AuthContext);


import React, {createContext, useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    // const navigate = useNavigate();

    useEffect(() => {
        // Проверяем токен при загрузке
        const token = localStorage.getItem("token");

        if (token) {

            const decoded = jwtDecode(token);

            console.log(decoded, 'decoded-decoded')
            console.log(decoded?.userId, 'decoded-decoded?.userId')

            // Запрашиваем роль пользователя с сервера
            fetch(`https://metaswap.freeblock.site/api/v1/account/get-user?userId=${decoded?.userId}`, {
                method: "GET",
                headers: {
                    Authorization: `${token}`,
                },
            })
                .then((res) => res.json())
                .then((data) => {

                    console.log(data?.userRole, 'data?.userRole')

                    if (data?.userRole) {
                        setUser({ role: data?.userRole, user: decoded }); // Храним роль в памяти
                    } else {
                        // logout();
                        alert()
                    }
                })
                .catch();
        }
    }, []);

    const login = async ({ email, password, setIsLogin }) => {
        const response = await fetch("https://metaswap.freeblock.site/api/v1/account/admin/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email, password }),
        });

        const data = await response.json();

        if (response.ok) {
            // localStorage.setItem("token", data.token); // Сохраняем только токен
            console.log(data, 'data--')
            if(data?.kind == 'OK') {
                const decoded = jwtDecode(data?.message);

                localStorage.setItem("token", data?.message); // Сохраняем только токен
                setUser({ role: 'admin', user: decoded }); // Храним роль в памяти
                setIsLogin(true)

                console.log(decoded, 'decoded--')
                console.log(data?.message, 'data--')
            }

        } else {
            throw new Error(data.message || "Ошибка авторизации");
        }
    };

    const logout = (handleLogout) => {
        localStorage.removeItem("token");
        setUser(null);
        handleLogout()
        // navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ user, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
