import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import LogoIcon from "../../assets/img/logo-icon";
// import Logo from "../../assets/img/logo"; // Logo is now a React component

const Sidebar = ({page}) => {


    const { login, loginLoading, logout, user } = useAuth(); // Используем метод login из AuthContext
    //
    useEffect(()=>{
        console.log(user, 'user-user')
    }, [user])

    const NavItem = ({ icon, text, active, link }) => (
        <li className="nav__item">
            <Link to={link} className={`nav__link ${active ? 'active' : ''}`}>
                <div className="nav__link-img">
                    {/* Uncomment and correct the path to SVG sprite */}
                    {/*<svg className="nav__link-icon">*/}
                    {/*    <use xlinkHref={`#sprite_${icon}`} /> /!* Ensure sprite ID is correct *!/*/}
                    {/*</svg>*/}
                </div>
                <span className="nav__link-text">{text}</span>
            </Link>
        </li>
    );

    return (
        <div className="sidebar">
            <a href="#" className="sidebar__logo">
                <div className="logo">
                    {/*<Logo height={27} className="logo__icon"/>*/}
                    <div className="logo__text">
                        <div className="logo__name">Metaswap</div>
                        <div className="logo__descr">Admin</div>
                    </div>
                    {/*<LogoIcon  width={40}/>*/}
                </div>
            </a>
            <nav className="sidebar__nav nav">
                <ul className="nav__list">
                    <NavItem link={'/'}  text="Пользователи" active={page == 'users' ? true : false} />
                    <NavItem link={'/commissions-and-charges'}  text="Управление комиссиями" active={page == 'commissions-and-charges' ? true : false}/>
                    <NavItem link={'/news'}  text="Новости" active={page == 'news' ? true : false}/>
                    <NavItem link={'/documents'}  text="Документы" active={page == 'documents' ? true : false}/>
                    <NavItem link={'/transactions'}  text="Транзакции" active={page == 'transactions' ? true : false}/>
                    <NavItem link={'/feedback'}  text="Feedback" active={page == 'feedbacks' ? true : false}/>
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
