import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useNews } from "../../hooks/useNews";

const NewsEditPage = ({ handleLogout }) => {
    const { id } = useParams(); // Получаем id новости из параметров маршрута
    const navigate = useNavigate(); // Для навигации после успешного обновления

    const {
        fetchGetNewsById,
        newsById,
        newsByIdLoading,
        newsByIdError,
        fetchUpdateNews,
        updateNewsLoading,
        updateNewsError,
        updateNewsAnswer,
    } = useNews();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    // Загружаем данные новости при монтировании компонента
    useEffect(() => {
        fetchGetNewsById(id);
    }, [fetchGetNewsById, id]);

    // Заполняем поля формы данными новости при их получении
    useEffect(() => {
        if (newsById) {
            setTitle(newsById.title || "");
            setContent(newsById.content || "");
        }
    }, [newsById]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetchUpdateNews(id, title, content);
        if (!updateNewsError) {
            navigate("/news"); // Перенаправляем на страницу списка новостей после успешного обновления
        }
    };

    return (
        <div className="wrapper">
            <Sidebar page={"news"} />
            <div className="page">
                <Header handleLogout={handleLogout} />
                <main className="main news-edit">
                    <h1 className="news__title title" style={{ marginBottom: 20 }}>Edit News</h1>

                    {newsByIdLoading ? (
                        <p>Loading...</p>
                    ) : newsByIdError ? (
                        <p style={{ color: "red" }}>Error: {newsByIdError.message}</p>
                    ) : (
                        <form onSubmit={handleSubmit} className="news-edit-form">
                            <div className="form-group">
                                <label htmlFor="title">Title</label>
                                <input
                                    type="text"
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    className="form-control"
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label htmlFor="content">Content</label>
                                <textarea
                                    id="content"
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    className="form-control"
                                    rows="10"
                                    required
                                ></textarea>
                            </div>

                            <button type="submit" className="button" disabled={updateNewsLoading}>
                                {updateNewsLoading ? "Updating..." : "Save Changes"}
                            </button>
                        </form>
                    )}

                    {/*{updateNewsError && <p style={{ color: "red" }}>Error: {updateNewsError.message}</p>}*/}
                    {/*{updateNewsAnswer && <p style={{ color: "green" }}>News updated successfully!</p>}*/}
                </main>
                <Footer />
            </div>
        </div>
    );
};

export default NewsEditPage;
