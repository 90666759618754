import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {useDocuments} from "../../hooks/useDocument";

const DocumentEditPage = ({ handleLogout }) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const {
        fetchGetDocumentById,
        documentById,
        documentByIdLoading,
        documentByIdError,
        fetchUpdateDocument,
        updateDocumentLoading,
        updateDocumentError,
    } = useDocuments();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    useEffect(() => {
        fetchGetDocumentById(id);
    }, [fetchGetDocumentById, id]);

    useEffect(() => {
        if (documentById) {
            setTitle(documentById.title || "");
            setContent(documentById.content || "");
        }
    }, [documentById]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetchUpdateDocument(id, title, content);
        navigate("/documents");
    };

    return (
        <div className="wrapper">
            <Sidebar page="documents" />
            <div className="page">
                <Header handleLogout={handleLogout} />
                <main className="main document-edit">
                    <h1 className="title">Edit Document</h1>
                    {documentByIdLoading ? (
                        <p>Loading...</p>
                    ) : documentByIdError ? (
                        <p style={{ color: "red" }}>Error: {documentByIdError.message}</p>
                    ) : (
                        <form onSubmit={handleSubmit} className="document-form">
                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label>Content</label>
                                <textarea
                                    value={content}
                                    onChange={(e) => setContent(e.target.value)}
                                    required
                                />
                            </div>
                            <button className="button" type="submit" disabled={updateDocumentLoading}>
                                {updateDocumentLoading ? "Updating..." : "Save Changes"}
                            </button>
                        </form>
                    )}
                </main>
                <Footer />
            </div>
        </div>
    );
};

export default DocumentEditPage;
