import React from "react";

const Footer = () => {
    return (
        <footer className="footer">
            <p className="footer__copy">@ 2024. All rights reserved</p>
            <div className="footer__menu menu">
                {/*<nav className="menu__body">*/}
                {/*    <ul className="menu__list">*/}
                {/*        {['Privacy Policy', 'Terms', 'Blog', 'Contacts'].map((item, index) => (*/}
                {/*            <li key={index} className="menu__item">*/}
                {/*                <a href="#" className="menu__link">{item}</a>*/}
                {/*            </li>*/}
                {/*        ))}*/}
                {/*    </ul>*/}
                {/*</nav>*/}
            </div>
        </footer>
    )
}

export default Footer;
