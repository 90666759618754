import React, {useEffect, useState} from 'react';
import '../assets/css/style.css'
import {Link} from "react-router-dom";
import Out from "../assets/img/out";
import {useAuth} from "../context/AuthContext";

const Header = ({handleLogout}) => {


    const { login, loginLoading, logout, user } = useAuth(); // Используем метод login из AuthContext
    //
    // useEffect(()=>{
    //     console.log(user, 'user-user')
    // }, [user])

    return (

        <header className="header">
            <form action="#" className="header__search form">
                {/*<div className="form__item">*/}
                {/*    <div className="form-control form-control--search">*/}
                {/*        <button className="form-control__btn">*/}
                {/*            <svg className="form-control__icon">*/}
                {/*                <use xlinkHref="img/sprite.svg#search" />*/}
                {/*            </svg>*/}
                {/*        </button>*/}
                {/*        <input*/}
                {/*            className="form-control__input"*/}
                {/*            type="text"*/}
                {/*            placeholder="Type here..."*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </form>
            <div
                style={{cursor: 'pointer'}}
                onClick={() => {
                    logout(handleLogout)
                }}
                className="header__out"
            >
                {/*<img src={require('../../assets/img/svg/out.svg')} alt="logout" />*/}
                <Out/>
            </div>
        </header>
    )
}

export default Header;
