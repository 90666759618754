import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { useDocuments } from "../../hooks/useDocument";

const DocumentsListPage = ({ handleLogout }) => {
    const {
        allDocumentsAnswer,
        allDocumentsLoading,
        allDocumentsError,
        fetchGetAllDocuments,
        fetchDeleteDocument,
        deleteDocumentLoading,
    } = useDocuments();

    const [filterText, setFilterText] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedDocumentId, setSelectedDocumentId] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchGetAllDocuments();
    }, [fetchGetAllDocuments]);

    const handleEdit = (id) => {
        navigate(`/documents/edit/${id}`);
    };

    const handleDeleteClick = (id) => {
        setSelectedDocumentId(id);
        setShowDeleteModal(true);
    };

    const handleDeleteConfirm = () => {
        fetchDeleteDocument(selectedDocumentId);
        setShowDeleteModal(false);
    };

    const handleDeleteCancel = () => {
        setSelectedDocumentId(null);
        setShowDeleteModal(false);
    };

    const filteredDocuments =
        allDocumentsAnswer?.filter((doc) =>
            doc.title.toLowerCase().includes(filterText.toLowerCase())
        ) || [];

    const columns = [
        { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
        { name: "Title", selector: (row) => row.title, sortable: true, width: "300px" },
        {
            name: "Created At",
            selector: (row) => new Date(row.createdAt).toLocaleString(),
            sortable: true,
            width: "200px",
        },
        {
            name: "Actions",
            cell: (row) => (
                <div style={{ display: "flex", gap: "10px" }}>
                    <button onClick={() => handleEdit(row.id)} className="documents-edit-btn">
                        Edit
                    </button>
                    <button onClick={() => handleDeleteClick(row.id)} className="documents-delete-btn" disabled={deleteDocumentLoading}>
                        {deleteDocumentLoading ? "Deleting..." : "Delete"}
                    </button>
                </div>
            ),
            width: "200px",
        },
    ];

    return (
        <div className="wrapper">
            <Sidebar page="documents" />
            <div className="page">
                <Header handleLogout={handleLogout} />
                <main className="main documents">
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <h1 className="documents__title title" style={{marginBottom: 20}}>Документы</h1>
                        <Link to={'/documents/new'} className="documents-create-btn">
                            Создать документ
                        </Link>
                    </div>
                    <div className="datatable-top-block">
                        <input
                            type="text"
                            placeholder="Search by Title or ID"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className="datatable-search-input"
                        />
                    </div>
                    {allDocumentsLoading ? (
                        <p>Loading...</p>
                    ) : allDocumentsError ? (
                        <p style={{color: "red"}}>Error: {allDocumentsError.message}</p>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={filteredDocuments}
                            pagination
                            highlightOnHover
                        />
                    )}
                </main>
                <Footer/>
            </div>

            {/* Модальное окно для подтверждения удаления */}
            {showDeleteModal && (
                <div className="documents-modal-overlay">
                    <div className="documents-modal">
                        <h2>Confirm Deletion</h2>
                        <p>Are you sure you want to delete this document?</p>
                        <div className="documents-modal-actions">
                            <button onClick={handleDeleteConfirm} className="documents-confirm-btn" disabled={deleteDocumentLoading}>
                                {deleteDocumentLoading ? "Deleting..." : "Yes, Delete"}
                            </button>
                            <button onClick={handleDeleteCancel} className="documents-cancel-btn">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DocumentsListPage;
