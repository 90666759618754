import { useState, useCallback } from 'react';
import {
    getAdminDocumentsApi,
    getAdminDocumentByIdApi,
    createAdminDocumentApi,
    updateAdminDocumentApi,
    deleteAdminDocumentApi,
} from '../utils/api/adminDocumentApi';

/**
 * Хук для управления документами (Админ)
 */
export const useDocuments = () => {
    // Состояния для получения всех документов
    const [allDocumentsLoading, setAllDocumentsLoading] = useState(false);
    const [allDocumentsError, setAllDocumentsError] = useState(null);
    const [allDocumentsAnswer, setAllDocumentsAnswer] = useState(null);

    // Состояния для получения документа по ID
    const [documentByIdLoading, setDocumentByIdLoading] = useState(false);
    const [documentByIdError, setDocumentByIdError] = useState(null);
    const [documentById, setDocumentById] = useState(null);

    // Состояния для создания документа
    const [createDocumentLoading, setCreateDocumentLoading] = useState(false);
    const [createDocumentError, setCreateDocumentError] = useState(null);
    const [createDocumentAnswer, setCreateDocumentAnswer] = useState(null);

    // Состояния для обновления документа
    const [updateDocumentLoading, setUpdateDocumentLoading] = useState(false);
    const [updateDocumentError, setUpdateDocumentError] = useState(null);
    const [updateDocumentAnswer, setUpdateDocumentAnswer] = useState(null);

    // Состояния для удаления документа
    const [deleteDocumentLoading, setDeleteDocumentLoading] = useState(false);
    const [deleteDocumentError, setDeleteDocumentError] = useState(null);
    const [deleteDocumentAnswer, setDeleteDocumentAnswer] = useState(null);

    /**
     * Функция для получения всех документов
     */
    const fetchGetAllDocuments = useCallback(async () => {
        setAllDocumentsLoading(true);
        setAllDocumentsError(null);
        setAllDocumentsAnswer(null);

        try {
            const data = await getAdminDocumentsApi();
            setAllDocumentsAnswer(data?.documents);
        } catch (err) {
            setAllDocumentsError(err);
        } finally {
            setAllDocumentsLoading(false);
        }
    }, []);

    /**
     * Функция для получения документа по ID
     */
    const fetchGetDocumentById = useCallback(async (id) => {
        setDocumentByIdLoading(true);
        setDocumentByIdError(null);
        setDocumentById(null);

        try {
            const data = await getAdminDocumentByIdApi(id);
            setDocumentById(data?.documents);
        } catch (err) {
            setDocumentByIdError(err);
        } finally {
            setDocumentByIdLoading(false);
        }
    }, []);

    /**
     * Функция для создания документа
     */
    const fetchCreateDocument = useCallback(async (title, content) => {
        setCreateDocumentLoading(true);
        setCreateDocumentError(null);
        setCreateDocumentAnswer(null);

        try {
            const data = await createAdminDocumentApi(title, content);
            setCreateDocumentAnswer(data);
            fetchGetAllDocuments(); // Обновляем список документов
        } catch (err) {
            setCreateDocumentError(err);
        } finally {
            setCreateDocumentLoading(false);
        }
    }, [fetchGetAllDocuments]);

    /**
     * Функция для обновления документа
     */
    const fetchUpdateDocument = useCallback(async (id, title, content) => {
        setUpdateDocumentLoading(true);
        setUpdateDocumentError(null);
        setUpdateDocumentAnswer(null);

        try {
            const data = await updateAdminDocumentApi(id, title, content);
            setUpdateDocumentAnswer(data);
            fetchGetAllDocuments(); // Обновляем список документов
        } catch (err) {
            setUpdateDocumentError(err);
        } finally {
            setUpdateDocumentLoading(false);
        }
    }, [fetchGetAllDocuments]);

    /**
     * Функция для удаления документа
     */
    const fetchDeleteDocument = useCallback(async (id) => {
        setDeleteDocumentLoading(true);
        setDeleteDocumentError(null);
        setDeleteDocumentAnswer(null);

        try {
            const data = await deleteAdminDocumentApi(id);
            setDeleteDocumentAnswer(data);
            fetchGetAllDocuments(); // Обновляем список документов
        } catch (err) {
            setDeleteDocumentError(err);
        } finally {
            setDeleteDocumentLoading(false);
        }
    }, [fetchGetAllDocuments]);

    return {
        // Состояния и функции для всех документов
        allDocumentsLoading,
        allDocumentsError,
        allDocumentsAnswer,
        fetchGetAllDocuments,

        // Состояния и функции для получения документа по ID
        documentByIdLoading,
        documentByIdError,
        documentById,
        fetchGetDocumentById,

        // Состояния и функции для создания документа
        createDocumentLoading,
        createDocumentError,
        createDocumentAnswer,
        fetchCreateDocument,

        // Состояния и функции для обновления документа
        updateDocumentLoading,
        updateDocumentError,
        updateDocumentAnswer,
        fetchUpdateDocument,

        // Состояния и функции для удаления документа
        deleteDocumentLoading,
        deleteDocumentError,
        deleteDocumentAnswer,
        fetchDeleteDocument,
    };
};
