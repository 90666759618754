import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import { useAuth } from "../../context/AuthContext"; // Подключаем AuthContext

const LoginPage = ({ setIsLogin }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const [showPassword, setShowPassword] = useState(false); // Состояние для показа/скрытия пароля

    const { login, loginLoading } = useAuth(); // Используем метод login из AuthContext

    const handleSubmit = async () => {
        if (!email || !password) {
            setError("Email и пароль обязательны");
            return;
        }

        try {
            setError(null);
            await login({ email, password, setIsLogin }); // Вызываем метод login из контекста
        } catch (err) {
            console.error("Ошибка авторизации:", err);
            setError("Неверный email или пароль");
        }
    };

    return (
        <div className="login-container">
            <div className="login-form">
                <h2>Admin Login</h2>
                {error && <p className="error-message">{error}</p>}
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Enter your email"
                        required
                    />
                </div>
                <div className="form-group password-group">
                    <label htmlFor="password">Password</label>
                    <div className="password-input-wrapper">
                        <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                        <button
                            type="button"
                            className="toggle-password-btn"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? "👁️" : "🙈"}
                        </button>
                    </div>
                </div>
                <button onClick={handleSubmit} type="button" className="login-button" disabled={loginLoading}>
                    {loginLoading ? "Logging in..." : "Login"}
                </button>
            </div>
        </div>
    );
};

export default LoginPage;
