// import React, { useEffect, useState } from "react";
// import Sidebar from "../../components/Sidebar";
// import Header from "../../components/Header";
// import Footer from "../../components/Footer";
// import DataTable from "react-data-table-component";
// import { useAdminUsers } from "../../hooks/useAdminUsers"; // Подключаем хук
//
// const UsersPage = ({ handleLogout }) => {
//     const [filterText, setFilterText] = useState(""); // Состояние для поиска
//     const [fromDate, setFromDate] = useState(""); // Начальная дата
//     const [toDate, setToDate] = useState(""); // Конечная дата
//
//     const {
//         usersData,
//         loading,
//         error,
//         fetchAdminUsers,
//         blockAdminUser,
//         blockLoading,
//         blockError,
//         successMessage,
//
//
//         blockAdminLoading,
//         blockAdminError,
//         fetchBlockAdmin
//     } = useAdminUsers();
//
//     useEffect(() => {
//         fetchAdminUsers({ page: 1, limit: 1000000 });
//     }, [fetchAdminUsers]);
//
//     const handleGetData = () => {
//         const params = {
//             page: 1,
//             limit: 1000000,
//             ...(fromDate && { startDate: fromDate }),
//             ...(toDate && { endDate: toDate }),
//         };
//         fetchAdminUsers(params);
//     };
//
//     const resetFilters = () => {
//         setFilterText("");
//         setFromDate("");
//         setToDate("");
//         fetchAdminUsers({ page: 1, limit: 1000000 });
//     };
//
//     const handleBlockUser = async (userId, isBlocked) => {
//         await blockAdminUser(userId, isBlocked);
//     };
//
//     const filteredUsers =
//         usersData?.users.filter((user) => {
//             const searchTerm = filterText.toLowerCase();
//             return (
//                 user.telegramUsername?.toLowerCase().includes(searchTerm) ||
//                 user.id.toString().includes(searchTerm) ||
//                 user.firstName?.toLowerCase().includes(searchTerm) ||
//                 user.lastName?.toLowerCase().includes(searchTerm)
//             );
//         }) || [];
//
//
//     const columns = [
//         { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
//         { name: "First Name", selector: (row) => row.firstName || "-", sortable: true, width: "150px" },
//         { name: "Last Name", selector: (row) => row.lastName || "-", sortable: true, width: "150px" },
//         { name: "Telegram Username", selector: (row) => row.telegramUsername || "-", sortable: true, width: "200px" },
//         { name: "Role", selector: (row) => row.userRole || "-", sortable: true, width: "120px" },
//         { name: "Public ID", selector: (row) => row.publicId, sortable: true, width: "150px" },
//         {
//             name: "Date Registered",
//             selector: (row) => formatDate(row.createdAt),
//             sortable: true,
//             width: "200px",
//         },
//         {
//             name: "Status",
//             cell: (row) => (
//                 <span style={{ color: row.userPermission === "BLOCK" ? "red" : "green" }}>
//                     {row.userPermission === "BLOCK" ? "Blocked" : "Active"}
//                 </span>
//             ),
//             width: "120px",
//         },
//         {
//             name: "Action",
//             cell: (row) => (
//                 <button
//                     style={{ width: "100px" }}
//                     className="bann-btn"
//                     onClick={() => handleBlockUser(row.id, row.userPermission !== "BLOCK")}
//                     disabled={blockLoading}
//                 >
//                     {/*{blockLoading && <span>Loading...</span>}*/}
//                     {row.userPermission === "BLOCK" ? "Unblock" : "Block"}
//                 </button>
//             ),
//             width: "150px",
//         },
//     ];
//
//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         return date.toLocaleString();
//     };
//
//     return (
//         <div className="wrapper">
//             <Sidebar page={"users"} />
//             <div className="page">
//                 <Header handleLogout={handleLogout} />
//                 <main className="main users">
//                     <h1 className="users__title title" style={{ marginBottom: 20 }}>Users</h1>
//
//                     {/*{successMessage && <p style={{ color: "green" }}>{successMessage}</p>}*/}
//                     {/*{blockError && <p style={{ color: "red" }}>Error: {blockError.message}</p>}*/}
//
//                     <div className="datatable-top-block">
//                         <input
//                             type="text"
//                             placeholder="Search by ID or Username"
//                             value={filterText}
//                             onChange={(e) => setFilterText(e.target.value)}
//                             className="datatable-search-input"
//                         />
//                     </div>
//
//                     <div className="users__table table-users table" style={{ overflowX: "auto" }}>
//                         {loading ? (
//                             <p>Loading...</p>
//                         ) : error ? (
//                             <p>Error: {error.message}</p>
//                         ) : (
//                             <DataTable
//                                 columns={columns}
//                                 data={filteredUsers}
//                                 pagination
//                                 highlightOnHover
//                                 fixedHeader
//                             />
//                         )}
//                     </div>
//                 </main>
//                 <Footer />
//             </div>
//         </div>
//     );
// };
//
// export default UsersPage;


import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DataTable from "react-data-table-component";
import { useAdminUsers } from "../../hooks/useAdminUsers"; // Подключаем хук

const UsersPage = ({ handleLogout }) => {
    const [filterText, setFilterText] = useState(""); // Состояние для поиска
    const [fromDate, setFromDate] = useState(""); // Начальная дата
    const [toDate, setToDate] = useState(""); // Конечная дата

    const {
        usersData,
        loading,
        error,
        fetchAdminUsers,
        blockAdminUser,
        blockLoading,
        blockError,
        successMessage,
        blockAdminLoading,
        blockAdminError,
        fetchBlockAdmin,
    } = useAdminUsers();

    useEffect(() => {
        fetchAdminUsers({ page: 1, limit: 1000000 });
    }, [fetchAdminUsers]);

    const handleGetData = () => {
        const params = {
            page: 1,
            limit: 1000000,
            ...(fromDate && { startDate: fromDate }),
            ...(toDate && { endDate: toDate }),
        };
        fetchAdminUsers(params);
    };

    const resetFilters = () => {
        setFilterText("");
        setFromDate("");
        setToDate("");
        fetchAdminUsers({ page: 1, limit: 1000000 });
    };

    const handleBlockUser = async (userId, isBlocked, isAdmin) => {
        if (isAdmin) {
            await fetchBlockAdmin(userId, isBlocked);
        } else {
            await blockAdminUser(userId, isBlocked);
        }
        handleGetData();
    };

    const filteredUsers =
        usersData?.users.filter((user) => {
            const searchTerm = filterText.toLowerCase();
            return (
                user.telegramUsername?.toLowerCase().includes(searchTerm) ||
                user.id.toString().includes(searchTerm) ||
                user.firstName?.toLowerCase().includes(searchTerm) ||
                user.lastName?.toLowerCase().includes(searchTerm)
            );
        }) || [];

    const columns = [
        { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
        { name: "First Name", selector: (row) => row.firstName || "-", sortable: true, width: "150px" },
        { name: "Last Name", selector: (row) => row.lastName || "-", sortable: true, width: "150px" },
        { name: "Telegram Username", selector: (row) => row.telegramUsername || "-", sortable: true, width: "200px" },
        { name: "Role", selector: (row) => row.userRole || "-", sortable: true, width: "120px" },
        { name: "Public ID", selector: (row) => row.publicId, sortable: true, width: "150px" },
        {
            name: "Date Registered",
            selector: (row) => formatDate(row.createdAt),
            sortable: true,
            width: "200px",
        },
        {
            name: "Status",
            cell: (row) => {
                const permission = row.userRole === "ADMIN" ? row.adminPermission : row.userPermission;
                return (
                    <span style={{ color: permission === "BLOCK" ? "red" : "green" }}>
                        {permission === "BLOCK" ? "Blocked" : "Active"}
                    </span>
                );
            },
            width: "120px",
        },
        {
            name: "Action",
            cell: (row) => {
                const isAdmin = row.userRole === "ADMIN";
                const permission = isAdmin ? row.adminPermission : row.userPermission;
                return (
                    <button
                        style={{ width: "100px" }}
                        className="bann-btn"
                        onClick={() => handleBlockUser(row.id, permission !== "BLOCK", isAdmin)}
                        disabled={isAdmin ? blockAdminLoading : blockLoading}
                    >
                        {permission === "BLOCK" ? "Unblock" : "Block"}
                    </button>
                );
            },
            width: "150px",
        },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    };

    return (
        <div className="wrapper">
            <Sidebar page={"users"} />
            <div className="page">
                <Header handleLogout={handleLogout} />
                <main className="main users">
                    <h1 className="users__title title" style={{ marginBottom: 20 }}>Users</h1>

                    {/*{successMessage && <p style={{ color: "green" }}>{successMessage}</p>}*/}
                    {/*{blockError && <p style={{ color: "red" }}>Error: {blockError.message}</p>}*/}
                    {/*{blockAdminError && <p style={{ color: "red" }}>Error: {blockAdminError.message}</p>}*/}

                    <div className="datatable-top-block">
                        <input
                            type="text"
                            placeholder="Search by ID or Username"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className="datatable-search-input"
                        />
                    </div>

                    <div className="users__table table-users table" style={{ overflowX: "auto" }}>
                        {loading ? (
                            <p>Loading...</p>
                        ) : error ? (
                            <p>Error: {error.message}</p>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={filteredUsers}
                                pagination
                                highlightOnHover
                                fixedHeader
                            />
                        )}
                    </div>
                </main>
                <Footer />
            </div>
        </div>
    );
};

export default UsersPage;
