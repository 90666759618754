import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useNews } from "../../hooks/useNews";

const NewsCreate = ({ handleLogout }) => {
    const navigate = useNavigate(); // Для навигации после успешного создания

    const {
        fetchCreateNews,
        createNewsLoading,
        createNewsError,
        createNewsAnswer,
    } = useNews();

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        await fetchCreateNews(title, content);
        if (!createNewsError) {
            navigate("/news"); // Перенаправляем на страницу списка новостей после успешного создания
        }
    };

    return (
        <div className="wrapper">
            <Sidebar page={"news"} />
            <div className="page">
                <Header handleLogout={handleLogout} />
                <main className="main news-create">
                    <h1 className="news__title title" style={{ marginBottom: 20 }}>Create News</h1>

                    <form onSubmit={handleSubmit} className="news-create-form">
                        <div className="form-group">
                            <label htmlFor="title">Title</label>
                            <input
                                type="text"
                                id="title"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="form-control"
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="content">Content</label>
                            <textarea
                                id="content"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                className="form-control"
                                rows="10"
                                required
                            ></textarea>
                        </div>

                        <button type="submit" className="button" disabled={createNewsLoading}>
                            {createNewsLoading ? "Creating..." : "Create News"}
                        </button>
                    </form>

                    {createNewsError && <p style={{ color: "red", marginTop: "20px" }}>Error: {createNewsError.message}</p>}
                    {createNewsAnswer && <p style={{ color: "green", marginTop: "20px" }}>News created successfully!</p>}
                </main>
                <Footer />
            </div>
        </div>
    );
};

export default NewsCreate;
