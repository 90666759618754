import { useState, useCallback } from 'react';
import {
    getAdminNewsApi,
    getAdminNewsByIdApi,
    createAdminNewsApi,
    updateAdminNewsApi,
    deleteAdminNewsApi
} from '../utils/api/adminNewsApi';

/**
 * Хук для управления новостями (Админ)
 */
export const useNews = () => {
    // Состояния для получения всех новостей
    const [allNewsLoading, setAllNewsLoading] = useState(false);
    const [allNewsError, setAllNewsError] = useState(null);
    const [allNewsAnswer, setAllNewsAnswer] = useState(null);

    // Состояния для получения новости по ID
    const [newsByIdLoading, setNewsByIdLoading] = useState(false);
    const [newsByIdError, setNewsByIdError] = useState(null);
    const [newsById, setNewsById] = useState(null);

    // Состояния для создания новости
    const [createNewsLoading, setCreateNewsLoading] = useState(false);
    const [createNewsError, setCreateNewsError] = useState(null);
    const [createNewsAnswer, setCreateNewsAnswer] = useState(null);

    // Состояния для обновления новости
    const [updateNewsLoading, setUpdateNewsLoading] = useState(false);
    const [updateNewsError, setUpdateNewsError] = useState(null);
    const [updateNewsAnswer, setUpdateNewsAnswer] = useState(null);

    // Состояния для удаления новости
    const [deleteNewsLoading, setDeleteNewsLoading] = useState(false);
    const [deleteNewsError, setDeleteNewsError] = useState(null);
    const [deleteNewsAnswer, setDeleteNewsAnswer] = useState(null);

    /**
     * Функция для получения всех новостей
     */
    const fetchGetAllNews = useCallback(async () => {
        setAllNewsLoading(true);
        setAllNewsError(null);
        setAllNewsAnswer(null);

        try {
            const data = await getAdminNewsApi();
            setAllNewsAnswer(data?.news);
        } catch (err) {
            setAllNewsError(err);
        } finally {
            setAllNewsLoading(false);
        }
    }, []);

    /**
     * Функция для получения новости по ID
     */
    const fetchGetNewsById = useCallback(async (id) => {
        setNewsByIdLoading(true);
        setNewsByIdError(null);
        setNewsById(null);

        try {
            const data = await getAdminNewsByIdApi(id);
            setNewsById(data?.news);
        } catch (err) {
            setNewsByIdError(err);
        } finally {
            setNewsByIdLoading(false);
        }
    }, []);

    /**
     * Функция для создания новости
     */
    const fetchCreateNews = useCallback(async (title, content) => {
        setCreateNewsLoading(true);
        setCreateNewsError(null);
        setCreateNewsAnswer(null);

        try {
            const data = await createAdminNewsApi(title, content);
            setCreateNewsAnswer(data);
            fetchGetAllNews(); // Обновляем список новостей
        } catch (err) {
            setCreateNewsError(err);
        } finally {
            setCreateNewsLoading(false);
        }
    }, [fetchGetAllNews]);

    /**
     * Функция для обновления новости
     */
    const fetchUpdateNews = useCallback(async (id, title, content) => {
        setUpdateNewsLoading(true);
        setUpdateNewsError(null);
        setUpdateNewsAnswer(null);

        try {
            const data = await updateAdminNewsApi(id, title, content);
            setUpdateNewsAnswer(data);
            fetchGetAllNews(); // Обновляем список новостей
        } catch (err) {
            setUpdateNewsError(err);
        } finally {
            setUpdateNewsLoading(false);
        }
    }, [fetchGetAllNews]);

    /**
     * Функция для удаления новости
     */
    const fetchDeleteNews = useCallback(async (id) => {
        setDeleteNewsLoading(true);
        setDeleteNewsError(null);
        setDeleteNewsAnswer(null);

        try {
            const data = await deleteAdminNewsApi(id);
            setDeleteNewsAnswer(data);
            fetchGetAllNews(); // Обновляем список новостей
        } catch (err) {
            setDeleteNewsError(err);
        } finally {
            setDeleteNewsLoading(false);
        }
    }, [fetchGetAllNews]);

    return {
        // Состояния и функции для всех новостей
        allNewsLoading,
        allNewsError,
        allNewsAnswer,
        fetchGetAllNews,

        // Состояния и функции для получения новости по ID
        newsByIdLoading,
        newsByIdError,
        newsById,
        fetchGetNewsById,

        // Состояния и функции для создания новости
        createNewsLoading,
        createNewsError,
        createNewsAnswer,
        fetchCreateNews,

        // Состояния и функции для обновления новости
        updateNewsLoading,
        updateNewsError,
        updateNewsAnswer,
        fetchUpdateNews,

        // Состояния и функции для удаления новости
        deleteNewsLoading,
        deleteNewsError,
        deleteNewsAnswer,
        fetchDeleteNews,
    };
};
