import apiClient from '../apiClient';

export const getAdminFeesApi = async (type) => {
    try {
        const response = await apiClient.get(`/fees/get-fee?type=${type}`);
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error fetching users:', error);
        throw error;
    }
};

export const changeFeesApi = async (type, amount) => {
    try {
        const response = await apiClient.post(`/fees`, {
            type: type,
            amount: amount
        });
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error updating block status:', error);
        throw error;
    }
};

export const updateFeesApi = async (type, amount) => {
    try {
        const response = await apiClient.post(`/fees/update`, {
            type: type,
            amount: amount
        });
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error updating block status:', error);
        throw error;
    }
};
