// // src/pages/TransactionsListPage.jsx
//
// import React, { useEffect } from "react";
// import Sidebar from "../../components/Sidebar";
// import Header from "../../components/Header";
// import Footer from "../../components/Footer";
// import DataTable from "react-data-table-component";
// import { useTransactions } from "../../hooks/useTransactions";
//
// const TransactionsListPage = ({ handleLogout }) => {
//     const { transactions, loading, error, fetchTransactions } = useTransactions();
//
//     useEffect(() => {
//         fetchTransactions();
//     }, [fetchTransactions]);
//
//     const columns = [
//         { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
//         { name: "Type", selector: (row) => row.type, sortable: true, width: "150px" },
//         { name: "Status", selector: (row) => row.status, sortable: true, width: "150px" },
//         { name: "Deposit State", selector: (row) => row.depositState, sortable: true, width: "200px" },
//         { name: "Currency", selector: (row) => row.currency.cryptoCode, sortable: true, width: "100px" },
//         { name: "Value", selector: (row) => row.value, sortable: true, width: "100px" },
//         {
//             name: "Created At",
//             selector: (row) => new Date(row.createdAt).toLocaleString(),
//             sortable: true,
//             width: "200px",
//         },
//     ];
//
//     return (
//         <div className="wrapper">
//             <Sidebar page="transactions" />
//             <div className="page">
//                 <Header handleLogout={handleLogout} />
//                 <main className="main transactions">
//                     <h1 className="transactions__title title" style={{ marginBottom: 20 }}>
//                         Transactions
//                     </h1>
//                     {loading ? (
//                         <p>Loading...</p>
//                     ) : error ? (
//                         <p style={{ color: "red" }}>Error: {error.message}</p>
//                     ) : (
//                         <DataTable
//                             columns={columns}
//                             data={transactions}
//                             pagination
//                             highlightOnHover
//                         />
//                     )}
//                 </main>
//                 <Footer />
//             </div>
//         </div>
//     );
// };
//
// export default TransactionsListPage;


// src/pages/TransactionsListPage.jsx

import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DataTable from "react-data-table-component";
import { useTransactions } from "../../hooks/useTransactions";

const TransactionsListPage = ({ handleLogout }) => {
    const { transactions, loading, error, fetchTransactions } = useTransactions();
    const [filterText, setFilterText] = useState(""); // Состояние для поля поиска
    const [filteredTransactions, setFilteredTransactions] = useState([]); // Отфильтрованные данные

    useEffect(() => {
        fetchTransactions();
    }, [fetchTransactions]);

    useEffect(() => {
        // Фильтрация транзакций при изменении текста поиска
        if (filterText.trim()) {
            setFilteredTransactions(
                transactions.filter(
                    (transaction) =>
                        transaction.id.toLowerCase().includes(filterText.toLowerCase()) ||
                        transaction.type.toLowerCase().includes(filterText.toLowerCase()) ||
                        transaction.status.toLowerCase().includes(filterText.toLowerCase())
                )
            );
        } else {
            setFilteredTransactions(transactions);
        }
    }, [filterText, transactions]);

    const columns = [
        { name: "ID", selector: (row) => row.id, sortable: true, width: "80px" },
        { name: "Type", selector: (row) => row.type, sortable: true, width: "150px" },
        { name: "Status", selector: (row) => row.status, sortable: true, width: "150px" },
        { name: "Deposit State", selector: (row) => row.depositState, sortable: true, width: "200px" },
        { name: "Currency", selector: (row) => row.currency.cryptoCode, sortable: true, width: "100px" },
        { name: "Value", selector: (row) => row.value, sortable: true, width: "100px" },
        {
            name: "Created At",
            selector: (row) => new Date(row.createdAt).toLocaleString(),
            sortable: true,
            width: "200px",
        },
    ];

    return (
        <div className="wrapper">
            <Sidebar page="transactions" />
            <div className="page">
                <Header handleLogout={handleLogout} />
                <main className="main transactions">
                    <h1 className="transactions__title title" style={{ marginBottom: 20 }}>
                        Transactions
                    </h1>
                    <div className="datatable-top-block" style={{ marginBottom: 20 }}>
                        <input
                            type="text"
                            placeholder="Search by ID, Type, or Status"
                            value={filterText}
                            onChange={(e) => setFilterText(e.target.value)}
                            className="datatable-search-input"
                            style={{
                                width: "100%",
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                fontSize: "14px",
                            }}
                        />
                    </div>
                    {loading ? (
                        <p>Loading...</p>
                    ) : error ? (
                        <p style={{ color: "red" }}>Error: {error.message}</p>
                    ) : (
                        <DataTable
                            columns={columns}
                            data={filteredTransactions} // Используем отфильтрованные данные
                            pagination
                            highlightOnHover
                        />
                    )}
                </main>
                <Footer />
            </div>
        </div>
    );
};

export default TransactionsListPage;
