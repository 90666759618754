import apiClient from '../apiClient';

export const getAdminUsersApi = async () => {
    try {
        const response = await apiClient.get('/account/get-alluser');
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error fetching users:', error);
        throw error;
    }
};

export const getAdminUserByIdApi = async (id) => {
    try {
        const response = await apiClient.get(`/account/get-user?userId=${id}`);
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error fetching users:', error);
        throw error;
    }
};

export const blockAdminUserApi = async (userId, isBlocked) => {
    try {
        const response = await apiClient.post(`/account/update-user-permission`, { userId: userId, permission: isBlocked ? 'BLOCK' : 'UNBLOCK' });
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error updating block status:', error);
        throw error;
    }
};

export const blockAdminApi = async (userId, isBlocked) => {
    try {
        const response = await apiClient.post(`/account/update-admin-permission`, { userId: userId, permission: isBlocked ? 'BLOCK' : 'UNBLOCK' });
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error updating block status:', error);
        throw error;
    }
};
