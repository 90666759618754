import { useState, useCallback } from 'react';
import {getAdminUsersApi, blockAdminUserApi, blockAdminApi, getAdminUserByIdApi} from '../utils/api/adminUsersApi';

/**
 * Хук для управления пользователями (Админ)
 */
export const useAdminUsers = () => {

    const [usersData, setUsersData] = useState(null); // Состояние для данных пользователей
    const [loading, setLoading] = useState(false); // Общее состояние загрузки
    const [error, setError] = useState(null); // Состояние для ошибок

    const fetchAdminUsers = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getAdminUsersApi();
            setUsersData(data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    }, []);


    const [userData, setUserData] = useState(null); // Состояние для данных пользователей
    const [userLoading, setUserLoading] = useState(false); // Общее состояние загрузки
    const [userError, setUserError] = useState(null); // Состояние для ошибок

    const fetchAdminUserById = useCallback(async (id) => {
        setUserLoading(true);
        setUserError(null);
        try {
            const data = await getAdminUserByIdApi(id);
            setUserData(data);
        } catch (err) {
            setUserError(err);
        } finally {
            setUserLoading(false);
        }
    }, []);


    // Дополнительные состояния
    const [blockLoading, setBlockLoading] = useState(false); // Состояние загрузки для блокировки
    const [blockError, setBlockError] = useState(null); // Ошибка при блокировке
    const [successMessage, setSuccessMessage] = useState(null); // Сообщение об успешной операции



    /**
     * Функция для блокировки/разблокировки пользователя
     */
    const blockAdminUser = useCallback(async (userId, isBlocked) => {
        setBlockLoading(true);
        setBlockError(null);
        setSuccessMessage(null);
        try {
            await blockAdminUserApi(userId, isBlocked);
            setSuccessMessage(`User ${isBlocked ? 'blocked' : 'unblocked'} successfully.`);
            // Обновляем список пользователей после блокировки/разблокировки
            fetchAdminUsers();
        } catch (err) {
            setBlockError(err);
        } finally {
            setBlockLoading(false);
        }
    }, []);



    // Дополнительные состояния
    const [blockAdminLoading, setBlockAdminLoading] = useState(false); // Состояние загрузки для блокировки
    const [blockAdminError, setBlockAdminError] = useState(null); // Ошибка при блокировке



    const fetchBlockAdmin = useCallback(async (userId, isBlocked) => {
        setBlockAdminLoading(true);
        setBlockAdminError(null);
        try {
            await blockAdminApi(userId, isBlocked);
            // Обновляем список пользователей после блокировки/разблокировки
            fetchAdminUsers();
        } catch (err) {
            setBlockAdminError(err);
        } finally {
            setBlockAdminLoading(false);
        }
    }, []);


    return {
        usersData, // Данные пользователей
        loading, // Состояние загрузки
        error, // Ошибки
        fetchAdminUsers, // Метод для получения пользователей


        // Новые состояния и функции
        blockLoading,    // Состояние загрузки для блокировки
        blockError,      // Ошибка при блокировке
        successMessage,  // Сообщение об успешной блокировке
        blockAdminUser,  // Функция для блокировки/разблокировки пользователя

        blockAdminLoading,
        blockAdminError,
        fetchBlockAdmin,

        userData,
        userLoading,
        userError,
        fetchAdminUserById
    };
};
