import apiClient from '../apiClient';

export const getAdminNewsApi = async () => {
    try {
        const response = await apiClient.get(`/news`);
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error fetching users:', error);
        throw error;
    }
};

export const getAdminNewsByIdApi = async (id) => {
    try {
        const response = await apiClient.get(`/news/get-news?id=${id}`);
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error fetching users:', error);
        throw error;
    }
};

export const createAdminNewsApi = async (title, content) => {
    try {
        const response = await apiClient.post(`/news`, {
            title: title,
            content: content
        });
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error updating block status:', error);
        throw error;
    }
};

export const deleteAdminNewsApi = async (id) => {
    try {
        const response = await apiClient.delete(`/news`, {
            headers: {
                'Content-Type': 'application/json',
            },
            data: { id: id }, // Передаем id в теле запроса
        });
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error updating block status:', error);
        throw error;
    }
};



export const updateAdminNewsApi = async (id, title, content) => {
    console.log(id, title, content, '=-=-=-')

    try {
        const response = await apiClient.post(`/news/update`, {
            id: parseFloat(id),
            title: title,
            content: content
        });
        return response.data;
    } catch (error) {
        console.error('[Admin Users API] Error updating block status:', error);
        throw error;
    }
};
