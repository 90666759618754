// src/hooks/useFeedbacks.js

import { useState, useCallback } from "react";
import { getAllFeedbacksApi, getFeedbacksByUserIdApi, deleteFeedbackApi } from "../utils/api/feedbacksApi";

/**
 * Хук для управления отзывами.
 */
export const useFeedbacks = () => {
    // Состояния для получения всех отзывов
    const [allFeedbacks, setAllFeedbacks] = useState([]);
    const [allFeedbacksLoading, setAllFeedbacksLoading] = useState(false);
    const [allFeedbacksError, setAllFeedbacksError] = useState(null);

    // Состояния для получения отзыва по ID
    const [feedbackById, setFeedbackById] = useState(null);
    const [feedbackByIdLoading, setFeedbackByIdLoading] = useState(false);
    const [feedbackByIdError, setFeedbackByIdError] = useState(null);

    // Состояния для удаления отзыва
    const [deleteFeedbackLoading, setDeleteFeedbackLoading] = useState(false);
    const [deleteFeedbackError, setDeleteFeedbackError] = useState(null);
    const [deleteFeedbackResponse, setDeleteFeedbackResponse] = useState(null);

    /**
     * Получение всех отзывов.
     */
    const fetchAllFeedbacks = useCallback(async () => {
        setAllFeedbacksLoading(true);
        setAllFeedbacksError(null);
        try {
            const data = await getAllFeedbacksApi();
            setAllFeedbacks(data?.feedbacks || []);
        } catch (err) {
            setAllFeedbacksError(err);
        } finally {
            setAllFeedbacksLoading(false);
        }
    }, []);

    /**
     * Получение отзыва по ID пользователя.
     * @param {number} userId - ID пользователя.
     */
    const fetchFeedbackByUserId = useCallback(async (userId) => {
        setFeedbackByIdLoading(true);
        setFeedbackByIdError(null);
        setFeedbackById(null);
        try {
            const data = await getFeedbacksByUserIdApi(userId);
            setFeedbackById(data);
        } catch (err) {
            setFeedbackByIdError(err);
        } finally {
            setFeedbackByIdLoading(false);
        }
    }, []);

    /**
     * Удаление отзыва по ID.
     * @param {number} id - ID отзыва.
     */
    const fetchDeleteFeedback = useCallback(async (id) => {
        setDeleteFeedbackLoading(true);
        setDeleteFeedbackError(null);
        setDeleteFeedbackResponse(null);
        try {
            const response = await deleteFeedbackApi(id);
            setDeleteFeedbackResponse(response);
            setAllFeedbacks((prev) => prev.filter((feedback) => feedback.id !== id));
        } catch (err) {
            setDeleteFeedbackError(err);
        } finally {
            setDeleteFeedbackLoading(false);
        }
    }, []);

    return {
        // Получение всех отзывов
        allFeedbacks,
        allFeedbacksLoading,
        allFeedbacksError,
        fetchAllFeedbacks,

        // Получение отзыва по ID
        feedbackById,
        feedbackByIdLoading,
        feedbackByIdError,
        fetchFeedbackByUserId,

        // Удаление отзыва
        deleteFeedbackLoading,
        deleteFeedbackError,
        deleteFeedbackResponse,
        fetchDeleteFeedback,
    };
};
