import { useState, useCallback } from 'react';
import { getAdminUsersApi, blockAdminUserApi } from '../utils/api/adminUsersApi';
import {changeFeesApi, getAdminFeesApi, updateFeesApi} from "../utils/api/adminFeesApi";

/**
 * Хук для управления пользователями (Админ)
 */
export const useFees = () => {
    const [feesDataSEND, setFeesDataSEND] = useState(null); // Состояние для данных пользователей
    const [feesDataRECEIVE, setFeesDataRECEIVE] = useState(null); // Состояние для данных пользователей
    const [feesDataEXCHANGE, setFeesDataEXCHANGE] = useState(null); // Состояние для данных пользователей
    const [loading, setLoading] = useState(false); // Общее состояние загрузки
    const [error, setError] = useState(null); // Состояние для ошибок

    const fetchGetFees = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await getAdminFeesApi('SEND');
            setFeesDataSEND(data);
        } catch (err) {
            setFeesDataSEND(null);
        } finally {
            setLoading(false);
        }

        try {
            const data = await getAdminFeesApi('RECEIVE');
            setFeesDataRECEIVE(data);
        } catch (err) {
            setFeesDataRECEIVE(null);
        } finally {
            setLoading(false);
        }
        try {
            const data = await getAdminFeesApi('EXCHANGE');
            setFeesDataEXCHANGE(data);
        } catch (err) {
            setFeesDataEXCHANGE(null);
        } finally {
            setLoading(false);
        }
    }, []);


    const [changeFeesLoading, setChangeFeesLoading] = useState(false); // Состояние загрузки для блокировки
    const [changeFeesError, setChangeFeesError] = useState(null); // Ошибка при блокировке
    const [changeFeesAnswer, setChangeFeesAnswer] = useState(null); // Сообщение об успешной операции


    const fetchChangeFees = useCallback(async (type, amount) => {

        setChangeFeesLoading(true);
        setChangeFeesError(null);
        setChangeFeesAnswer(null);

        try {
            console.log(type, amount, 'type, amount')
            const data = await changeFeesApi(type, amount);
            setChangeFeesAnswer(data);
            // Обновляем список пользователей после блокировки/разблокировки
            fetchGetFees();
        } catch (err) {
            setChangeFeesError(err);
        } finally {
            setChangeFeesLoading(false);
        }
    }, [fetchGetFees]);


    const [updateFeesLoading, setUpdateFeesLoading] = useState(false); // Состояние загрузки для блокировки
    const [updateFeesError, setUpdateFeesError] = useState(null); // Ошибка при блокировке
    const [updateFeesAnswer, setUpdateFeesAnswer] = useState(null); // Сообщение об успешной операции


    const fetchUpdateFees = useCallback(async (type, amount) => {

        setUpdateFeesLoading(true);
        setUpdateFeesError(null);
        setUpdateFeesAnswer(null);

        try {
            console.log(type, amount, 'type, amount')
            const data = await updateFeesApi(type, amount);
            setUpdateFeesAnswer(data);
            // Обновляем список пользователей после блокировки/разблокировки
            fetchGetFees();
        } catch (err) {
            setUpdateFeesError(err);
        } finally {
            setUpdateFeesLoading(false);
        }
    }, [fetchGetFees]);



    return {
        feesDataSEND,
        feesDataRECEIVE,
        feesDataEXCHANGE,
        fetchGetFees,
        loading,
        error,

        changeFeesLoading,
        changeFeesError,
        changeFeesAnswer,
        fetchChangeFees,

        updateFeesLoading,
        updateFeesError,
        updateFeesAnswer,
        fetchUpdateFees
    };
};
